<template>
  <div class="certificates">
    <h3 class="certificates-title">
      {{ $t('views.certificates.title') }}
    </h3>
    <div
      v-if="hasCertificates"
    >
      <certificates-card
        v-for="order in getOrders"
        :key="order.id"
        class="certificates-orders"
        :order="order"
      />
    </div>
    <div
      v-else
      class="certificates-placeholder"
    >
      {{ $t('views.certificates.placeholder') }}
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CertificatesCard from '@/components/cards/certificates/CertificatesCard.vue';

export default {
  name: 'Certificates',
  components: {
    CertificatesCard,
  },
  computed: {
    ...mapGetters([
      'getOrders',
      'getBusiness',
    ]),
    hasCertificates() {
      return this.getOrders.length > 0;
    },
  },
  mounted() {
    this.fetchOrders({ business_id: this.getBusiness.id, by_status: 'paid' });
  },
  methods: {
    ...mapActions(['fetchOrders']),
  },
};
</script>
<style lang="scss" scoped>
  .certificates {
    background-color: $app_background;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0px 40px 0px 270px;

    .certificates-title {
      font-size: 1.5em;
      font-weight: bold;
      margin: 70px 0 40px 0;
      text-align: center;
    }

    .certificates-orders {
      margin-bottom: 20px;
    }

    .filter-select {
      width: 300px;
    }

    .certificates-placeholder {
      text-align: center;
      font-size: 1.3em;
    }
  }
</style>

<template>
  <div class="ceritifcates-card">
    <div class="card-background">
      <div
        class="certificate-content-title"
      >
        {{ $t('components.certificates_card.certificate_id', { id: order.id }) }}
      </div>

      <div class="certificate-content-title">
        {{ $t('components.certificates_card.created_at') }}
        <div class="font-weight-regular">
          {{ order.updated_at }}
        </div>
      </div>

      <div>
        <rectoplus-button
          class="show-button"
          :text="$t('components.certificates_card.show_certificate')"
          @click="showCertificate"
        />
      </div>
    </div>
    <div
      v-for="(materialType, index) in materialTypes"
      :key="index"
      class="credit-offered-content"
    >
      <div class="material-type-name">
        {{ $t(`components.certificates_card.material_names.${materialType}`) }}
      </div>

      <div class="material-quantity">
        {{ $t('components.certificates_card.material_amount', {
          quantity: order.material_quantity[materialType].quantity
        }) }}
      </div>
    </div>
  </div>
</template>
<script>
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'CertificatesCard',
  components: {
    RectoplusButton,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      materialTypes: [
        'paper',
        'plastic',
        'metal',
        'glass',
      ],
    };
  },
  methods: {
    showCertificate() {
      window.open(this.order.certify.url);
    },
  },
};
</script>
<style lang="scss" scoped>
  .ceritifcates-card {

    .card-background {
      padding: 0 20px 0 20px;
      display: flex;
      background-color: $color_light;
      border-radius: 10px 10px 0 0;
      box-shadow: 10px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;

      .certificate-content-title {
        font-weight: bold;
        font-size: 1.5em;
      }

      .show-button {
        width: 300px;
        height: 60px;
      }
    }

    .credit-offered-content {
      background-color: $color_light;
      display: flex;
      justify-content: flex-start;

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      .material-type-name {
        padding-left: 20px;
      }

      .material-quantity {
        padding-left: 20px;
        font-weight: bold;
      }
    }

  }
</style>
